import React from "react"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Container from "@components/layout/container"
import Button from "@components/elements/button"
import Heading from "@components/elements/heading"
import PropTypes from "prop-types"
import { Logo } from "@components/common/logo"
import { useTheme } from "styled-components"
import { LogoCarousel } from "@components/common/logo-carousel"
import { LowGradient } from "@components/common/gradient"
import Paragraph from "@components/elements/paragraph"
import { useGradient } from "@hooks/use-gradient"

export const PowerMetricsEOPCta = ({
  heading,
  description,
  logos,
  microsite,
  force
}) => {
  const { gradient } = useGradient()
  const { color } = useTheme()

  return (
    <Div position="relative" background={color.indigo700} overflow="hidden">
      <LowGradient src={gradient.cdn} loading="lazy" />

      {logos && <LogoCarousel />}

      <Container style={{ zIndex: "10", position: "relative" }}>
        <Flex center alignItems="center" padding="8rem 0 8rem" gap="2rem">
          {microsite ? (
            <Logo.PowerMetricsNoKlip />
          ) : (
            <Logo.PowerMetrics height="60px" />
          )}
          <Div>
            <Heading
              color={color.indigo200}
              fontSize="3rem"
              margin={description ? "0 0 1rem" : "0 0 0.5rem"}
              as="h2"
            >
              {heading ?? (
                <>
                  Data from everywhere.
                  <br />
                  Dashboards for everyone.
                </>
              )}
            </Heading>
            {description && (
              <Paragraph color="white" fontSize="1.4rem" lineHeight="130%">
                {description}
              </Paragraph>
            )}
          </Div>
          <Button.PowerMetrics force={force} data-cta-location="eop" text />
        </Flex>
      </Container>
    </Div>
  )
}

PowerMetricsEOPCta.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  logos: PropTypes.bool,
  microsite: PropTypes.bool,
  force: PropTypes.bool
}
