import React, { useState } from "react"
import Layout from "@components/layout"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { IntegrationsContent } from "@components/pages/integrations/content"

const Integrations = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Layout
      title={`PowerMetrics Integrations`}
      description={`PowerMetrics fits neatly into your data stack and integrates with all the data sources your teams work with every day.`}
      fullWidth
      marginless
      microsite
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <IntegrationsContent microsite />

      <PowerMetricsEOPCta
        microsite
        heading={"Make analytics everybody's business."}
        description={"Get started with a free account."}
      />
    </Layout>
  )
}

export default Integrations
